.App {
  font-family: sans-serif;
  text-align: center;
}

#footer {
  
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #404040;
}

#home .k-grid-content {
  height: 400px !important;
  overflow-x: hidden;
}
