.dashboard-card {
  padding: 15px 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}

.dashboard-card.light {
  background-color: #ffffff;
}

.dashboard-card .card-heading {
  text-align: center;
}

.dashboard-card .card-heading hr {
  width: 80px;
  border-top: 3px solid #8dc73f;
  margin-bottom: 30px;
}

.dashboard-card .k-chart {
  height: auto;
}

.dashboard-card.dashboard-card-col .card-heading svg,
.dashboard-card.dashboard-card-col .card-col svg{
  width: 14px;
}

.dashboard-card.dashboard-card-col .row > div{
  word-break: break-word;
}
.dashboard-card.dashboard-card-col .row > div:not(:last-child):before{
  content: "";
  position: absolute;
  width: 1px;
  height:  calc(100% + 15px);
  background: #D4D4D4;
  bottom: -15px;
  right: 0px;
}

.dashboard-card.dashboard-card-col h5{
  margin-bottom: 0px;
  margin-right: 16px;
}
.dashboard-card.dashboard-card-col .card-col p{
  font-size: 18px;
  margin-bottom: 0px;
}

.dashboard-card.dashboard-card-col .card-col .card-col-content{
  font-size: 32px;
  text-align: right;
  line-height: 1;
}
.dashboard-card.dashboard-card-col .card-col > div p{
  margin-right: 16px;
}

.dashboard-card.dashboard-card-col .info-text-red{
  color: #EE624B;
  word-break: normal;
}

#home .dashboard-card.dashboard-card-col .custom-font-size{
  font-size: 22px;
}

@media only screen and (max-width: 1024px) {
  .dashboard-card.dashboard-card-col .row > div:not(:last-child):before{
    content: "";
    position: absolute;
    width: 100%;
    height:  1px;
    background: #D4D4D4;
    bottom: 0px;
    right: 0px;
  }
  
}