#wrapper aside {
  width: 225px !important;
}

#wrapper aside.to-left {
  left: -225px !important;
}

.h1-main a,
.h1-main a:hover {
  color: #ffffff;
  text-decoration: none;
}

.side-menu-logout{
  background-color:transparent;
  border-top:0;
  border-bottom:0;
  border-left:0
}

.side-menu-user{
  color:#c6c6c6;
  margin-top: 10px;
}