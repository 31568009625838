.nav-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  height: 100%;
  color: #ffffff;
  background-color: #000000;
  border: 3px solid #000;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .33);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .33);
  text-decoration: none;
  transition: all ease-in-out .3s;
}

.nav-card:hover {
  color: #8dc73f;
  border: 3px solid #8dc73f;
  text-decoration: none;
}

.nav-card figure {
  margin-bottom: 20px;
}

.nav-card figure img {
  height: 117px;
}

.nav-card .nav-card-title {
  font-family: Source Sans Pro;
  font-size: 13px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}