.tab {
  margin-bottom: 50px;
}

.tab-head-actions .button-icon {
  font-size: 30px;
  margin: 0px 10px;
  color: #ee624b;
}

.tab .text-muted {
  color: #a0a0a0 !important;
}

.action-buttons {
  margin-bottom: 20px;
}

.action-buttons button {
  margin-right: 40px;
}

.tab form {
  margin-bottom: 30px;
}

.tab form .form-group label {
  display: block;
}

.btn.btn-success {
  color: #ffffff;
}

.icon-color-success svg {
  color: #7cce56;
}

.filters .filters-title {
  font-size: 12px;
}

.form-group .k-widget.k-datepicker.k-header {
  width: 100%;
}