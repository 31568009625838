/* PIC-BOX */
.pic-box img {
  display: inline-block;
  width: 100%;
}

.pic-box .pic-box-border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  padding: 8px 10px;
}

.pic-box .pic-box-border .content span {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 18px;
  color: #7cce56;
}

.pic-box .actions {
  display: flex;
}

.pic-box .actions .button-icon {
  font-size: 22px;
  margin: 0px 8px;
}
/* END OF PIC-BOX */


/* CUSTOM-TABLE */
.table.table-custom,
.table.table-custom thead th {
  border: none !important;
}

.table.table-custom thead th {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  background-color: #7cce56;
  padding: 8px 10px;
}

.table.table-custom tbody tr td {
  font-family: Roboto;
  font-weight: 300;
  font-size: 13px;
  background-color: #f1f1f1;
  padding: 8px 10px;
}

.table.table-custom tbody tr:hover td {
  background-color: #7cce56;
}

.table.table-custom tbody tr td.col-head {
  font-weight: 700;
}

.table.table-custom tbody tr td {
  border: none;
  border-bottom: 1px solid #d4d4d4;
}

.table.table-custom tbody tr:last-child td {
  border-bottom: none;
}

.table.table-custom.table-dark thead th {
  background-color: #000000;
  color: #8dc73f;
  border-left: 1px solid #8dc73f !important;
}

.table.table-custom.table-dark thead th:first-child {
  border-left: none !important;
}

.table.table-custom.table-dark tbody tr td {
  color: #000000;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.table.table-custom.table-dark tbody tr td:first-child {
  border-left: none;
}

.table.table-custom.table-dark tbody tr td:last-child {
  border-right: none;
}

.table.table-custom.table-dark tbody tr:hover td {
  background-color: #bfdc98;
}



.table.table-custom.table-light thead th {
  background-color: #d4d4d4;
  color: #404040;
  border-left: 1px solid #ffffff !important;
}

.table.table-custom.table-light thead th:first-child {
  border-left: none !important;
}

.table.table-custom.table-light tbody tr td {
  color: #404040;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.table.table-custom.table-light tbody tr td:first-child {
  border-left: none;
}

.table.table-custom.table-light tbody tr td:last-child {
  border-right: none;
}

.table.table-custom.table-light tbody tr:hover td {
  background-color: #bfdc98;
}
/* END OF CUSTOM-TABLE */


/* TEXT UTILITIES */
.text-success {
  color: #7cce56 !important;
}

.text-accent {
  color: #ee624b !important;
}

.text-inf {
  color: #198cda !important;
}
/* END OF TEXT-UTILITIES */

/* MAIN NAV */
.main-nav {
  background-color: #8dc73f;
  margin: 20px 0px;
}

.main-nav.red-colored{
  background-color: #EE624B;
}

.main-nav .nav-item {
  margin-right: 30px;
}

.main-nav .nav-item .nav-link {
  color: #ffffff;
  font-size: 14px;
  line-height: 51px;
  border-radius: 0;
  position: relative;
  padding: 0;
}

.main-nav.small-nav .nav-item .nav-link {
  font-size: 13px;
}

.main-nav .nav-item .nav-link.active {
  background-color: #000000;
}

.main-nav .nav-item .nav-link::after {
  content: ' ';
  position: absolute;
  height: 3px;
  width: 0;
  bottom: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #404040;
  -webkit-transition: ease-in-out all .3s;
  transition: ease-in-out all .3s;
}

.main-nav .nav-item .nav-link:hover::after {
  width: 100%;
}

.main-nav .nav-item .nav-link.active:hover::after {
  width: 0;
}
/* END OF MAIN NAV */



/* BUTTON ICONS */
.button-icon {
  background: transparent;
  border: none;
  padding: 0;
}

.button-icon svg {
  display: block;
  width: 100%;
}
/* END OF BUTTON ICONS */

.container-fluid.container-s{
  padding: 0 40px;
}

/* VETIS MATCHING COLORING */
input.vetis-match {
  border: 1px solid #7cce56;
}

input.vetis-mismatch {
  border: 1px solid #ee624b;
}

.k-datepicker.vetis-match .k-picker-wrap {
  border-color: #7cce56;
}

.k-datepicker.vetis-mismatch .k-picker-wrap {
  border-color: #ee624b;
}

.k-dropdown.vetis-match .k-dropdown-wrap {
  border-color: #7cce56;
}

.k-dropdown.vetis-mismatch .k-dropdown-wrap {
  border-color: #ee624b;
}

.k-combobox.vetis-match .k-dropdown-wrap {
  border-color: #7cce56;
}

.k-combobox.vetis-mismatch .k-dropdown-wrap {
  border-color: #ee624b;
}
/* END OF VETIS MATCHING COLORING */

/* DIALOG */
.k-widget.k-window.k-dialog{
  text-align: left;
  font-weight: 400;
}
/* END OF DIALOG */
