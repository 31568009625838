/* Donut charts style */

#home .home-donut-charts .k-chart.k-widget {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 247px;
  border-radius: 5px;
  background: #f1f1f1;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}


#home .home-donut-charts .chart-circle-text {
  color: #707070;
}

#home .home-donut-charts .chart-circle-text > div {
  font-size: 36px;
  line-height: 1;
  color: #1f78b4;
}

/* Table panels style */

#home .dashboard-card.table-container h5 {
  position: relative;
}

#home .dashboard-card.table-container h5:before {
  content: "";
  position: absolute;
  width: 80px;
  height: 3px;
  background: #8dc73f;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
}
