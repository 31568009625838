.tab .tab-head .info-text-red{
    color: #EE624B;
}

.tab form .form-group label.info-text-blue{
    color: #198CDA;
}

.tab form .form-group input,
.tab form .form-group span.k-dropdown,
.tab form .form-group > textarea
form.table-filter .form-group .k-input.k-textbox, .k-textarea, .k-textbox,
form.table-filter .form-group span.k-dropdown,
form.table-filter .form-group span.k-datepicker,
form.table-filter .form-group > textarea{
    width: 100% !important;
}

.tab form .form-group > textarea.fixed-height{
       height: 122px
}

.tab .tab-nav-buttons a.btn{
    margin-right: 40px;
}

.tab .tab-nav-buttons a.btn.active{
    cursor: not-allowed;
    background: #00000030;
    border-color: #00000030;   
}


.tab .tab-nav-buttons a.btn.active:focus{
    box-shadow: none;
}

form.table-filter .form-group label.small{
    line-height: 1.2;
}